/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */

@tailwind preflight;







/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */

@tailwind components;
@font-face {
    font-family: 'Blackbike';
    src: url('/_assets/fonts/Blackbike-Clean.woff2') format('woff2'), url('/_assets/fonts/Blackbike-Clean.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Rough Cut';
    src: url('/_assets/fonts/RoughCut-RegularClean.woff2') format('woff2'), url('/_assets/fonts/RoughCut-RegularClean.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

body {
    @apply .bg-brand-tan .overflow-x-hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.nav {
    background-size: 300%;
    background-position: bottom center;
    background-repeat: repeat-x;
    background-image: url('/_assets/img/nav-wood.png');
    transition: .1s background-position-y ease-out .1s;
}

@screen md {
    .nav {
    background-size: 200%;
}
}

@screen lg {
    .nav {
        background-size: cover;
        background-position-y: -235px;
    }
}

.nav-logo-mobile {
    position: absolute;
    transition: .1s all ease-in-out;
}

@screen md {
   .nav-logo-mobile {
    left: 50%;
    transform: translateX(-50%);
   } 
}
.nav-logo{
    position: absolute;
    left: -1%;
    transition: .1s all ease-in-out;
}

.nav-logo .hd1 {
    transition: .1s fill ease-in-out;
}

.nav-logo:hover .hd1 {
    fill: white;
}

@screen lg {
    .nav-logo {
        opacity: 0;
        top: -20px;
    }
}


.nav--scrolled {
    background-position-y: bottom;
    transition: .1s background-position-y ease-out;
}

.nav--scrolled .nav-logo {
    opacity: 1;
    transition: .1s all ease-in-out .1s;
    top: 0px;
}


.hamburger {
    display: block;
}

.hamburger:focus {
    @apply outline-none;
}

@screen lg {
    .hamburger {
        display: none;
    }
}

.hamburger-box {
    height: 40px;
    width: 30px;
    display: block;
    position: relative;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    @apply bg-brand-tan;
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 5px;
    display: block;
    transition: .1s all ease-in-out;
}

.hamburger-inner {
    top: 50%;
    transform: translateY(-50%);
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--close .hamburger-inner {
    background-color: transparent;
}

.hamburger--close .hamburger-inner::before {
    top: 0;
    transform: rotate(45deg);
}

.hamburger--close .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.hamburger p {
    transition: .1s all ease-in-out;
}
.hamburger--close p {
    opacity: 0;
}


.hero {
    background-size: cover;
    position: relative;
    overflow: hidden;
}

#Logo_Open {
    display: none;
}

@screen lg {
    #Logo_Open {
        display: inline;
    }
}

.hero::before {
    content: "";
    background-color: #281301;
    opacity: .5;
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.hero--home {
    position: relative;
}

.hero--home::before {
    display: none;
}


video {

    min-width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100%;
}

@screen lg {
    video {
        height: auto;
    }
}

.ben {
    position: relative;
    top: -3rem;
}

@screen md {
    .ben {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: scale3d(1.2, 1.2, 1);
        transform-origin: bottom right;
    }
}

@screen lg {
    .ben {
        transform: scale(1);
    }
}

@screen xl {

    .ben {
        position: absolute;
        top: -13rem;
        right: 0;
    }
}

.bg-wood {
    background-image: url("/_assets/img/wood-light.jpg");
    background-size: cover;
}

.contact-bg {
    background-image: url("/_assets/img/big-dust.jpg");
    background-size: cover;
}








/*
.bg-wood-dark {
  background-image: url("/_assets/img/wood-dark.png");
  background-size: cover;
}
*/

.bg-wood-medium {
    background-image: url("/_assets/img/wood-medium.jpg");
    background-size: cover;
}

.team-image-container {
    transform-origin: center;
    animation-fill-mode: both;
}

.team-image-container+h2 {
    transition: .4s font-size ease-in-out;
}

.saw-mask, .team-image {
    transform: rotate(0deg);

}


.team-image-container .saw-mask, .team-image-container .team-image{
    backface-visibility: hidden;
}
.team-image-container a {

} 
.team-image-container a h2{
    transition: .4s font-size ease-in-out;
}
.team-image-container a:hover .team-image {
    background-color: rgb(124,116,104);
}

.team-image-container a:hover h2{
    font-size: 3.5rem;
}
.team-image img {
    opacity: 0;
}
.team-image {
    background-image: url("/_assets/img/team.jpg");
    width:100%;
    height: 100%;
    background-blend-mode: luminosity;
    background-color: transparent;
    background-size: contain;
    transition: .4s background-color ease-in-out;
}

@screen lg {

    .footer-gallery img {
    opacity: .3;
    transition: .2s opacity ease-out;
}
.footer-gallery img:hover {
    opacity: 1;
}
}









/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

.menu {
    @apply list-reset justify-between font-roughcut uppercase;
    display: none;
}

.mobile-menu__wrap {
    top: 65px;
    z-index: 40;
    height: 0;
    overflow: hidden;
    transition: .2s height ease-in;
}

.mobile-menu__wrap--open {
    height: 100vh;
    transition: .2s height ease-out;
}

.mobile-menu {
    @apply list-reset mt-24 justify-between font-roughcut uppercase;
}


.menu-item {
    @apply flex-1 text-center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.50);
    transition: .1s transform ease-in-out;
    transform-origin: center;
}

.menu-item:hover {
    transform: scale(1.1);
}

.menu-item--mobile {
    @apply text-4xl mb-4;
    text-shadow: none;
}

.menu-link {
    color: #DBCBB0;
    @apply no-underline;
    transition: .1s color ease-out;
}

.menu-item--mobile .menu-link {
    @apply text-brand-brown-light;
}

.menu-item--mobile .menu-link--active {
    @apply text-white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.50);
}

.menu-link:hover,
.menu-link--active {
    color: white;
}

.button {
    @apply bg-brand-green inline-block font-hairline font-roughcut text-base text-white px-6 pt-4 pb-3 relative no-underline uppercase;
    box-shadow: inset 0px 0px 0px 2px #6C704C;
    transition: .2s background-color ease-out 0s, .2s color ease-out 0s, .2s box-shadow ease-out 0s !important;
}

.button:hover {
    @apply bg-brand-tan text-brand-brown;
    box-shadow: inset 0px 0px 0px 2px #EBE1D3;
}

.button--on-light:hover {
    @apply bg-brand-brown text-brand-tan;
    box-shadow: inset 0px 0px 0px 2px #3A2816;
}

.button:hover::after {
    background-image: url('/_assets/img/tan-blade.png');
    animation: .1s saw linear infinite;
}
.button--on-light::after {
    transition: .2s background-image ease-out;
}
.button--on-light:hover::after {
    background-image: url('/_assets/img/blade.png');
}

@keyframes saw {
    from {
        background-position: 0px 0px;
    }
    to {
        background-position: 10px 0px;
    }
}

.button::after {
    content: "";
    width: 100%;
    height: 10px;
    background-image: url('/_assets/img/green-blade.png');
    background-repeat-y: no-repeat;
    position: absolute;
    background-size: contain;
    left: 0;
    bottom: -10px;
    transition: .2s background-image ease-out;
}

.project-bar {
    @apply text-center py-6 absolute pin-b w-full;
    background-color: rgba(109, 112, 76, .85);
    transition: .3s background-color ease-in-out;
}

@screen md {
    .project-bar {
        @apply py-8;
    }
}

.project-bar::before {
    content: "";
    width: 100%;
    height: 10px;
    background-image: url('/_assets/img/green-blade.png');
    background-repeat-y: no-repeat;
    background-size: contain;
    position: absolute;
    transform: rotate(180deg) scaleX(-1);
    opacity: .85;
    left: 0;
    top: -10px;
    background-position-x: 0;
    transition: .2s all ease-in-out;
}

.featured-work:hover .project-bar {
    background-color: rgba(109, 112, 76, 1);
}

.featured-work:hover .project-bar::before {
    opacity: 1;
    animation: .1s project-bar linear infinite;
}

@keyframes project-bar {
    from {
        background-position-x: 0px;
    }
    to {

        background-position-x: 10px;
    }
}

.featured-work img {
    transition: 1s transform ease-in-out;
}

.featured-work:hover img {
    transform: scale(1.1);
}

.saw-edge {
    position: relative;
}

.saw-edge--top::before,
.saw-edge--bottom::after {
    content: "";
    width: 100%;
    height: 10px;
    background-repeat-y: no-repeat;
    position: absolute;
    background-size: contain;
    left: 0;
}

.saw-edge--top::before {
    top: -10px;
    transform: rotate(180deg);
}

.saw-edge--bottom::after {
    bottom: -10px;
}

.saw-edge--brown::before,
.saw-edge--brown::after {
    background-image: url('/_assets/img/brown-blade.png');
}

/*
.saw-edge--dark-brown::before,
.saw-edge--dark-brown::after {
    background-image: url('/_assets/img/dark-brown-blade.png');
}
*/


.saw-edge--wood::before,
.saw-edge--wood::after {
    background-image: url('/_assets/img/blade.png');
}

.saw-edge--green::before,
.saw-edge-green::after {
    background-image: url('/_assets/img/green-blade.png');
}

.saw-edge--black::before,
.saw-edge-black::after {
    background-image: url('/_assets/img/black-blade.png');
}

.saw-edge--tan::before,
.saw-edge-tan::after {
    background-image: url('/_assets/img/tan-blade.png');
}

.text-block>p {
    @apply font-sans font-normal text-brand-brown mb-6;
}

.text-block>ul {
    @apply font-sans font-normal text-brand-green mb-6;
}
.text-block a:not(.button) {
  @apply text-brand-green;
}

.content-block>div:nth-child(1) {
    order: 2;
}

@screen md {
    .content-block>div:nth-child(1) {
        order: 0;
    }
    .content-block:nth-child(odd)>div:nth-child(1) {
        order: 2;
    }
}

.intro-block {
    background-image: url("/_assets/img/dj-shadow.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    @apply bg-black;
}
@screen md {
.intro-block {
    background-size: cover;
}

}

.intro-block__text {
    @apply text-white text-base leading-tight font-sans font-normal;
}

.intro-block__text p {

    @apply mb-6;
}

.intro-block--featured {
    background-image: url("/_assets/img/saw-bg.jpg");
    background-size: cover;
}

.intro-block>img {
    transform: scaleX(-1);
}

.team-picture {
    @apply mx-auto;
    background-blend-mode: luminosity;
    background-size: contain;

    width:100%;
    height:175px;
    transition: .2s all ease-in-out;
}
@screen md {
   .team-picture {
    width: 125px;
    height: 125px;
   }
}
@screen lg {
   .team-picture {
    width: 150px;
    height: 150px;
   }
}


a .team-picture:hover {
    background-color: transparent !important;
}

@screen xl {
    .team-picture {
        width: auto;
        height: 275px;
    }
}

.saw-mask {
    mask-image: url('/_assets/img/saw-mask.png');
    mask-size: contain;
    mask-repeat: no-repeat;
}

.overflow {
    position: absolute;
    top:-20%;
}

@screen md {
    .overflow {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 21;
}

.overflow>img {
    transform: scale(1.2);
    transform-origin: center right;
}
}
.services-nav {
        @apply bg-brand-tan p-10;
    }

    .services-nav ul {
   display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@screen md {
    .services-nav ul {

    height: 100px;
    }
}
@screen lg {
    .services-nav {
        @apply bg-brand-tan p-10 absolute pin-r z-20;
        top: 50%;
        transform: translateY(-50%);
    }
     .services-nav ul {

    height: auto;
    }
}
@screen xl {
    .services-nav {
        @apply bg-transparent rounded-none;
    }
}

.services-nav__mask {
    @apply absolute;
    top: 50%;
    transform: translateY(-50%) scale(3);
    transform-origin: 10%;
    display: none;
}
@screen xl {
    .services-nav__mask {
    display: block;
    }
}


.service-item,
.service-item a {
    transition: .1s all ease-out;
}

/*
.service-item:hover {
    @apply text-brand-brown
}
*/

.service-item:hover a {
    @apply .text-brand-brown border-brand-brown border-solid border-b-2;
}

.active-service {
    @apply .text-brand-brown;
}

.active-service a {
    @apply .text-brand-brown border-brand-brown border-solid border-b-2;
}

.product-detail-content p {
    margin-bottom: .5rem;
}
.product-detail-content ul {
    margin-bottom: 1.5rem;
}

.product-detail-content li {
    margin-bottom: .2rem;
}


.input-label {
    @apply font-sans text-brand-tan text-xs uppercase;
}

.input {
    @apply block bg-transparent mt-2 text-base text-white pl-2 font-bold font-sans h-8 border-solid border-l border-b border-brand-tan;
}

.input:focus {
    @apply outline-none border-brand-gold;
}


.photo-gallery__wrap {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 50;
    opacity: 0;
    pointer-events: none;
    transition: .2s opacity ease-in;
}

.photo-gallery__background {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(58, 40, 22, .9);
    width: 100vw;
    height: 100vh;
    z-index: 51;
    cursor: pointer;
}

.photo-gallery__wrap--open {
    opacity: 1;
    pointer-events: all;
    transition: .2s opacity ease-out;
}

.photo-gallery {
    @apply bg-brand-tan p-3;
    position: absolute;
    z-index: 52;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}


.photo-gallery-open {
    overflow: hidden;
}



/*
@screen md {
    .slick-prev.quotes-arrow {
        left: 4rem !important;
        z-index: 20;
    }
    .slick-next.quotes-arrow {
        right: 4rem !important;
        z-index: 20;
    }
}

@screen lg {
    .slick-prev.quotes-arrow {
        left: 0rem !important;
        z-index: 20;
    }
    .slick-next.quotes-arrow {
        right: 0rem !important;
        z-index: 20;
    }
}

@screen xl {
    .slick-prev.quotes-arrow {
        left: 8rem !important;
        z-index: 20;
    }
    .slick-next.quotes-arrow {
        right: 8rem !important;
        z-index: 20;
    }
    .slick-prev.gallery-arrow {
        left: -50px !important;
        z-index: 20;
    }
    .slick-next.gallery-arrow {
        right: -50px !important;
        z-index: 20;
    }
}
*/

.slick-prev::before,
.slick-next::before {
    display: none;
}

.slick-next svg {
    transform: scaleX(-1);
    transform-origin: center;
}

.slick-arrow path {
    transition: .1s fill ease-in-out;
}

.slick-arrow:hover path {
    fell: #EBE1D3;
}
.slick-arrow {
    height: 42px !important;
}

.gallery-arrow svg {
    transform:scale(.5);
}
.gallery-arrow.slick-next svg {
    transform:scale(.5) scaleX(-1);
}


.wood-product a {
    overflow: hidden;
}

.wood-product img {
    transform: scale(1.1);
    transition: .3s opacity ease-in-out, 1s transform ease-in-out;
}

.wood-product:hover img {
    @apply opacity-100;
    transform: scale(1.2);
}

.wood-product-name {
    @apply font-roughcut font-hairline px-6 text-3xl text-brand-tan;
    text-shadow: 0px 1px 0px #3A2816;
}

.section-header__top {
    @apply text-brand-brown text-5xl leading-point-seven font-hairline font-blackbike;
}

.section-header__bottom {
    @apply text-brand-brown text-6xl leading-none font-hairline font-roughcut;
}



.social-link {
    @apply no-underline flex items-center;
}

.social-link__image {
    @apply w-8 opacity-75;
    transition: .1s opacity ease-in-out
}

.social-link__text {
    @apply text-brand-tan font-sans uppercase pl-2;
    transition: .1s color ease-in-out
}

.social-link:hover .social-link__image {
    @apply opacity-100;
}

.social-link:hover .social-link__text {
    @apply text-white;
}

[data-aos="button-fade-down"] {
    opacity: 0;
    transition-property: transform, opacity;
    transform: translateY(-50px);
}

[data-aos="button-fade-down"].aos-animate {
    opacity: 1;
    transform: translateY(0px);
}



/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */

@tailwind utilities;







/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */
